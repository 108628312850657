import Vue    from 'vue';
import App    from './App.vue';
import router from './router';


Vue.config.productionTip = false;

Vue.filter(
  'img',
  (value: string) => {
    return require('@/assets/' + value);
  },
);

Vue.filter(
  'sound',
  (value: string) => {
    return require('@/assets/sound/sound' + value + '.mp3');
  },
);

Vue.filter(
  'smallTree',
  (value: string) => {
    return require('@/assets/smallSizeTrees/' + value);
  },
);

Vue.filter(
  'bigTree',
  (value: string) => {
    return require('@/assets/bigSizeTrees/' + value);
  },
);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
