<template>
  <div id="app">
    <div id="nav">
      <router-link class="menu-link" to="/">tree view</router-link>
      <router-link class="menu-link" to="/video">video</router-link>
      <router-link class="menu-link" to="/about">about</router-link>
      <slider></slider>
    </div>
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>

<style lang="scss">
body {
  background-color: #000000;

  #app {
    #nav {
      height: 30px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      background-color: rgba(0, 0, 0, 0.5);

      a {
        display: inline-block;
        height: 30px;
        padding: 0 10px;
        line-height: 30px;
        text-decoration: none;
        color: #ffffff;

        &.router-link-exact-active {
          background-color: rgb(0, 140, 0);
        }

        &:not(.router-link-exact-active):hover {
          background-color: #006E2E;
        }
      }
    }
  }
}
</style>

<script>
import Slider from '@/components/slider';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Slider
  },
  data() {
    return {};
  }
})
export default class App extends Vue {
}
</script>
